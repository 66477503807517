import React, { CSSProperties, useState } from 'react'
import {
  Button,
  ButtonProps as RACButtonProps,
  TooltipTrigger
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import Tooltip from '@/src/stories/Tooltip'
import { twMerge } from 'tailwind-merge'
import { useRouter } from 'next/router'
import Badge, { BadgeSizes } from '@/src/stories/Badge'
import { t } from '@lingui/macro'
import { Users01 } from '@untitled-ui/icons-react'

export type AvatarSizes = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | '3xl'

interface AvatarProps extends RACButtonProps {
  size?: AvatarSizes
  label?: string
  imgUrl?: string
  name?: string
  email?: string
  withTooltip?: boolean
  showDetails?: boolean
  onlyShowEmailInTooltip?: boolean
  highlighted?: boolean
  onBrand?: boolean
  isLink?: boolean
  userId?: string
  teams?: string[]
  userType?: string
  deactivated?: boolean
}

const textStyles = tv({
  base: "uppercase text-center font-medium font-['Inter']",
  variants: {
    size: {
      xs: 'text-xs leading-[18px]',
      s: 'text-sm leading-tight',
      m: 'text-base leading-normal',
      l: 'text-lg leading-7',
      xl: 'text-xl leading-[30px]',
      xxl: 'text-2xl leading-loose',
      '3xl': 'text-3xl leading-[45px]'
    },
    isDeactivated: {
      true: 'text-neutral-300 '
    },
    onBrand: {
      true: 'text-neutral-100',
      false: 'text-neutral-900',
      undefined: 'text-neutral-900'
    }
  }
})

const styles = tv({
  base: 'overflow-hidden rounded-full outline-none flex items-center justify-center gap-2 shrink-0',
  variants: {
    highlighted: {
      true: 'ring-warning-400',
      false: 'ring-neutral-0'
    },
    imgUrl: {
      false: 'bg-neutral-50 border border-neutral-300'
    },
    size: {
      xs: 'w-6 h-6 ring-[1px] shadow',
      s: 'w-8 h-8 ring-[2px] shadow',
      m: 'w-10 h-10 ring-[3px] shadow',
      l: 'w-12 h-12 ring-[3px] shadow',
      xl: 'w-14 h-14 ring-[4px] shadow-lg',
      xxl: 'w-16 h-16 ring-[5px] shadow-lg',
      '3xl': 'w-20 h-20 ring-[6px] shadow-xl'
    },
    onPress: {
      true: 'cursor-pointer'
    },
    onBrand: {
      true: 'bg-neutral-800',
      false: 'bg-neutral-50',
      undefined: 'bg-neutral-50'
    }
  }
})

const containerStyles = tv({
  base: 'flex items-center gap-2 focus:outline-none w-full',
  variants: {
    isLink: {
      true: 'cursor-pointer hover:opacity-80 transition-opacity duration-200 ease-in-out'
    }
  }
})

const Avatar = ({ showDetails = true, ...props }: AvatarProps) => {
  const router = useRouter()
  const containerRef = React.useRef<HTMLDivElement>(null)

  const [imageError, setImageError] = useState(false)

  const onClick = props.isLink
    ? () => {
        router.push(`/dashboard?page=user&id=${props.userId}`)
      }
    : undefined
  const onBtnPress = () => {
    containerRef.current?.click()
  }

  return (
    <div className='w-full' ref={containerRef} onClick={onClick}>
      <TooltipTrigger
        isDisabled={!props.withTooltip || !(props.name || props.email)}
        delay={300}>
        <Button
          onPress={onBtnPress}
          className={containerStyles({
            isLink: !!props.isLink && !!props.userId
          })}>
          <div
            style={props.style as CSSProperties}
            className={twMerge(
              props.className as string,
              styles({
                highlighted: !!props.highlighted,
                size: props.size,
                imgUrl: !!props.imgUrl && !imageError,
                onPress: !!props.onPress || props.isLink,
                onBrand: !!props.onBrand
              })
            )}>
            {props.imgUrl && !imageError && (
              <img
                src={props.imgUrl}
                alt={props.label}
                className={`object-cover w-full h-full ${
                  !!props.deactivated ? 'filter grayscale' : ''
                }`}
                onError={() => setImageError(true)}
              />
            )}

            {(!props.imgUrl || imageError) && props.label && (
              <span
                className={textStyles({
                  size: props.size,
                  isDeactivated: !!props.deactivated,
                  onBrand: !!props.onBrand
                })}>
                {props.label.substring(0, 2)}
              </span>
            )}
          </div>

          {(props.name || props.email) && showDetails && (
            <div
              className={
                'flex-col text-left justify-start items-start overflow-hidden flex ml-1'
              }>
              {props.name && (
                <p
                  className={twMerge(
                    "block truncate ... self-stretch w-full text-xs font-semibold font-['Plus Jakarta Sans'] leading-tight",
                    props.onBrand ? 'text-neutral-100' : 'text-neutral-700'
                  )}>
                  {props.name}
                  {props.deactivated && (
                    <Badge
                      color={'neutral'}
                      size={BadgeSizes.s}
                      className={'ml-2'}>
                      {t`Deactivated`}
                    </Badge>
                  )}
                </p>
              )}
              {props.email && !props.onlyShowEmailInTooltip && (
                <p
                  className={twMerge(
                    "block truncate ... self-stretch w-full text-xs font-medium font-['Plus Jakarta Sans'] leading-tight",
                    props.onBrand ? 'text-neutral-100' : 'text-neutral-700'
                  )}>
                  {props.email}
                </p>
              )}

              {(props.teams || props.userType) && (
                <div className='flex items-center gap-1 justify-center mt-2'>
                  {props.teams && props.teams.length === 1 && (
                    <Badge
                      iconLeading={<Users01 height={12} width={12} />}
                      size={BadgeSizes.xs}>
                      {props.teams}
                    </Badge>
                  )}

                  {props.teams && props.teams.length > 1 && (
                    <Badge
                      iconLeading={<Users01 height={12} width={12} />}
                      color={'neutral'}
                      size={BadgeSizes.xs}>
                      {props.teams.length} {t`Teams`}
                    </Badge>
                  )}

                  {props.userType && (
                    <Badge size={BadgeSizes.xs}>{props.userType}</Badge>
                  )}
                </div>
              )}
            </div>
          )}
        </Button>

        <Tooltip placement={'top'}>
          <div className='sm:max-w-[20vw] break-all'>
            <Tooltip.Title>{props.name ?? ''}</Tooltip.Title>
            <Tooltip.SupportiveText>{props.email ?? ''}</Tooltip.SupportiveText>
          </div>
        </Tooltip>
      </TooltipTrigger>
    </div>
  )
}

export default Avatar
