import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { showToastError, showToastSuccess } from '@/utils/messages'
import { Database } from '@/db_types'
import { SupabaseTableHook, SupabaseTableHookQueryOperators } from '@/types'
import { useCallback } from 'react'
import { isNumber } from 'lodash'
import { useOrgContext } from '@/hooks/state/organization'
import { msg, t } from '@lingui/macro'
import { format } from 'date-fns'
import { i18n, MessageDescriptor } from '@lingui/core'
import { PostgrestFilterBuilder } from '@supabase/postgrest-js'
import { removeAccents } from '@/utils'

interface SupabaseTableHookGenericProps {
  tableName: string
  selectQuery?: string
}

export const ORG_FILTERS = [
  'activities',
  'csv_mappers',
  'custom_fields',
  'deals',
  'invitations',
  'invoices',
  'kpis',
  'kpis_tables',
  'metrics',
  'payouts',
  'plans',
  'profile_org_variable_salary',
  'statements',
  'targets',
  'table_config',
  'teams',
  'validation_steps'
]

const DB_ERRORS: Record<string, MessageDescriptor> = {
  DST01: msg`Statement must be approved before it can be marked as paid`,
  DST02: msg`All of a statement's compensations need to be validated before it can be marked as paid`
}

export const buildQuery = <T extends {}>(
  query: PostgrestFilterBuilder<Database['public'], T, T[]>,
  options: {
    selectedOrganizationId: number
    tableName: string
    skipOrgFilter?: boolean
  },
  filtersAndSorting: SupabaseTableHookQueryOperators
) => {
  const {
    order,
    range,
    eqFilters,
    neqFilters,
    isFilters,
    gtFilters,
    ltFilters,
    inFilters,
    containsFilters,
    ilikeFilters,
    timeRange,
    orFilter,
    notFilter,
    filters,
    limit,
    textSearch,
    rangeLt,
    rangeGt,
    overlaps
  } = filtersAndSorting

  if (order && Array.isArray(order)) {
    order.forEach(o => {
      query = query.order(o.column, o.options)
    })
  } else {
    query = query.order('created_at', { ascending: false })
  }

  if (
    options.selectedOrganizationId &&
    ORG_FILTERS.includes(options.tableName) &&
    !options.skipOrgFilter
  ) {
    query = query.eq('organization_id', options.selectedOrganizationId)
  }

  if (range && isNumber(range?.from) && isNumber(range?.to)) {
    query = query.range(range.from, range.to)
  }

  if (eqFilters) {
    eqFilters.forEach(filter => {
      query = query.eq(filter.column, filter.value)
    })
  }

  if (neqFilters) {
    neqFilters.forEach(filter => {
      query = query.neq(filter.column, filter.value)
    })
  }

  if (isFilters) {
    isFilters.forEach(filter => {
      if (!!filter.negate) {
        query = query.not(filter.column, 'is', filter.value)
      } else {
        query = query.is(filter.column, filter.value)
      }
    })
  }

  if (gtFilters) {
    gtFilters.forEach(filter => {
      if (filter.orEqual) {
        query = query.gte(filter.column, filter.value)
      } else {
        query = query.gt(filter.column, filter.value)
      }
    })
  }

  if (ltFilters) {
    ltFilters.forEach(filter => {
      if (filter.orEqual) {
        query = query.lte(filter.column, filter.value)
      } else {
        query = query.lt(filter.column, filter.value)
      }
    })
  }

  if (inFilters) {
    inFilters.forEach(filter => {
      query = query.in(filter.column, filter.values)
    })
  }

  if (containsFilters) {
    containsFilters.forEach(filter => {
      query = query.contains(filter.column, filter.values)
    })
  }

  if (ilikeFilters) {
    ilikeFilters.forEach(filter => {
      query = query.ilike(filter.column, filter.value)
    })
  }

  if (timeRange) {
    const { to, from, selectValue } = timeRange

    if (from) {
      query = query.gte(selectValue ?? 'created_at', format(from, 'yyyy-MM-dd'))
    }

    if (to) {
      query = query.lte(selectValue ?? 'created_at', format(to, 'yyyy-MM-dd'))
    }
  }

  if (orFilter) {
    query = query.or(
      typeof orFilter === 'string' ? orFilter : orFilter[0],
      typeof orFilter === 'string' ? undefined : orFilter[1]
    )
  }

  if (notFilter) {
    query = query.not(...notFilter)
  }

  if (filters) {
    if (Array.isArray(filters)) {
      filters.forEach(f => {
        query = query.filter(f.column, f.operator, f.value)
      })
    } else {
      query = query.filter(filters.column, filters.operator, filters.value)
    }
  }

  if (limit) {
    query = query.limit(limit)
  }

  if (textSearch) {
    query = query.textSearch(
      textSearch.column,
      removeAccents(textSearch.query),
      textSearch.options
    )
  }

  if (rangeLt) {
    query = query.rangeLt(rangeLt.column, rangeLt.value)
  }

  if (rangeGt) {
    query = query.rangeGt(rangeGt.column, rangeGt.value)
  }

  if (overlaps) {
    query = query.overlaps(overlaps.column, overlaps.value)
  }
}

//TODO: check types

const useSupabaseTable = <T, TInsert>(
  props: SupabaseTableHook<T> & SupabaseTableHookGenericProps
) => {
  const {
    tableName,
    selectQuery = '*',
    enabled = true,
    onSuccess,
    onError,
    setLoading,
    afterQuery,
    countItems,
    head,
    afterInsert,
    afterUpdate,
    afterUpdateItems,
    afterUpsert,
    afterDelete,
    eqFilters,
    queryKeys,
    upsertOptions,
    notify = true,
    notifyError = true,
    refetchOnWindowFocus,
    refetchOnMount,
    skipOrgFilter,
    rangeLt,
    rangeGt
  } = props

  const selectedOrganizationId = useOrgContext(state => state.id)

  const supabase = useSupabaseClient<Database>()
  const queryClient = useQueryClient()

  const fetchItems = async () => {
    let query = supabase
      .from(tableName)
      .select(selectQuery, countItems ? { count: 'exact', head } : undefined)

    buildQuery(
      query,
      {
        selectedOrganizationId: selectedOrganizationId!,
        tableName,
        skipOrgFilter
      },
      props
    )

    const { data: items, error, count } = await query

    if (error) throw error

    return { items, count }
  }

  const { data: items, isLoading } = useQuery(
    Array.isArray(queryKeys)
      ? [...(queryKeys ?? [tableName]), selectedOrganizationId]
      : `${tableName}-${selectedOrganizationId}`,
    fetchItems,
    {
      onError,
      onSuccess: data => {
        onSuccess?.(data as { items: T[]; count: number })
        afterQuery?.(data as { items: T[]; count: number })
      },
      enabled,
      refetchOnWindowFocus,
      refetchOnMount
    }
  )

  const getById = useCallback(
    async (id: number): Promise<T> => {
      const { data, error } = await supabase
        .from(tableName)
        .select(selectQuery)
        .eq('id', id)
        .single()

      if (error) throw error

      //TODO: fix this
      return data as T
    },
    [selectQuery, supabase, tableName]
  )

  const insertItem = useMutation(
    async (items: TInsert[]) => {
      if (
        selectedOrganizationId &&
        ORG_FILTERS.includes(tableName) &&
        !skipOrgFilter
      ) {
        items = items.map(item => ({
          ...item,
          organization_id: selectedOrganizationId
        }))
      }

      let query = supabase.from(tableName).insert(items).select(selectQuery)

      const { data, error } = await query

      if (error) throw error

      return data as T[]
    },
    {
      onSuccess: d => {
        setLoading?.(false)
        notify && showToastSuccess(t`Inserted successfully`)
        queryClient.invalidateQueries(queryKeys ?? [tableName])

        afterInsert?.(d as T[])
      },
      onError: (e: any) => {
        setLoading?.(false)
        notify &&
          showToastError(
            new Error(
              e.code && e.code in DB_ERRORS
                ? i18n._(DB_ERRORS[e.code])
                : t`Something went wrong, please try again later or contact support`,
              e
            )
          )
      }
    }
  )

  const updateItem = useMutation(
    async (
      item: Partial<T> & {
        id?: number | string | { [index: string]: number | string }
      }
    ) => {
      const { id, ...rest } = item
      let query = supabase.from(tableName).update(rest)

      if (typeof id === 'object') {
        Object.keys(id).forEach(field => (query = query.eq(field, id[field])))
      } else if (id) {
        query = query.eq('id', id)
      } else if (eqFilters) {
        eqFilters.forEach(filter => {
          query = query.eq(filter.column, filter.value)
        })
      }

      const { data, error } = await query.select(selectQuery)

      if (error) throw error

      return data[0] as T
    },
    {
      onSuccess: d => {
        setLoading?.(false)
        notify && showToastSuccess(t`Item updated`)
        queryClient.invalidateQueries(queryKeys ?? [tableName])

        afterUpdate?.(d)
      },
      onError: (e: any) => {
        setLoading?.(false)
        notify &&
          showToastError(
            new Error(
              e.code && e.code in DB_ERRORS
                ? i18n._(DB_ERRORS[e.code])
                : t`Something went wrong updating the item, please try again later or contact support`,
              e
            )
          )
      }
    }
  )

  const updateItems = useMutation(
    async ({
      ids,
      update
    }: {
      ids: (number | string)[]
      update: Partial<T>
    }) => {
      const { data, error } = await supabase
        .from(tableName)
        .update(update)
        .in('id', ids)
        .select(selectQuery)

      if (error) throw error

      return data as T[]
    },
    {
      onSuccess: d => {
        setLoading?.(false)
        notify && showToastSuccess(t`Items updated`)
        queryClient.invalidateQueries(queryKeys ?? [tableName])

        afterUpdateItems?.(d as T[])
      },
      onError: (e: any) => {
        setLoading?.(false)
        notify &&
          showToastError(
            new Error(
              e.code && e.code in DB_ERRORS
                ? i18n._(DB_ERRORS[e.code])
                : t`Something went wrong updating the items, please try again later or contact support`,
              e
            )
          )
      }
    }
  )

  const upsertItem = useMutation(
    async (items: (Partial<T> & { id?: number })[]) => {
      const { data, error } = await supabase
        .from(tableName)
        .upsert(items, upsertOptions)
        .select(selectQuery)

      if (error) throw error

      return data as T[]
    },
    {
      onSuccess: d => {
        setLoading?.(false)
        notify && showToastSuccess(t`Item updated`)
        queryClient.invalidateQueries(queryKeys ?? [tableName])

        afterUpsert?.(d)
      },
      onError: (e: any) => {
        setLoading?.(false)
        notify &&
          showToastError(
            new Error(
              e.code && e.code in DB_ERRORS
                ? i18n._(DB_ERRORS[e.code])
                : t`Something went wrong, please try again later or contact support`,
              e
            )
          )
      }
    }
  )

  const deleteItem = useMutation(
    async (
      id:
        | number
        | string
        | (number | string)[]
        | { [index: string]: number | string }
    ) => {
      let query = supabase.from(tableName).delete()

      if (typeof id === 'object' && !Array.isArray(id)) {
        Object.keys(id).forEach(field => (query = query.eq(field, id[field])))
      } else if (Array.isArray(id)) {
        query = query.in('id', id)
      } else {
        query = query.match({ id })
      }

      const { error } = await query
      if (error) throw error

      return id
    },
    {
      onSuccess: id => {
        setLoading?.(false)
        notify && notifyError && showToastSuccess(t`Item deleted`)
        queryClient.invalidateQueries(queryKeys ?? [tableName])

        afterDelete?.(id)
      },
      onError: (e: any) => {
        setLoading?.(false)
        notify &&
          showToastError(
            new Error(
              e.code && e.code in DB_ERRORS
                ? i18n._(DB_ERRORS[e.code])
                : t`Something went wrong, please try again later or contact support`,
              e
            )
          )
        onError?.(e)
      }
    }
  )

  const deleteJoinTableItem = useMutation(
    async (
      join: Record<string, string | number> | Record<string, string | number>[]
    ) => {
      let query = supabase.from(tableName).delete()

      if (Array.isArray(join)) {
        join.forEach(j => (query = query.match(j)))
      } else query = query.match(join)

      const { error } = await query

      if (error) throw error

      return join
    },
    {
      onSuccess: join => {
        setLoading?.(false)
        notify && showToastSuccess(t`Item deleted`)
        queryClient.invalidateQueries(queryKeys ?? [tableName])

        // TODO: fix this
        afterDelete?.(join as T)
      },
      onError: (e: any) => {
        setLoading?.(false)
        notify &&
          showToastError(
            new Error(
              e.code && e.code in DB_ERRORS
                ? i18n._(DB_ERRORS[e.code])
                : t`Something went wrong, please try again later or contact support`,
              e
            )
          )
      }
    }
  )

  return {
    items: items?.items as T[],
    count: items?.count ?? null,
    fetchItems,
    getById,
    isLoading,
    insertItem,
    updateItem,
    updateItems,
    upsertItem,
    deleteItem,
    deleteJoinTableItem
  }
}

export { useSupabaseTable }

export default useSupabaseTable
